.common_company_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  width: 100%;
  height: 100%;
}

.common_company_logo__svg {
  width: 100%;
  height: 100%;
}

.common_company_logo__path {
  display: inline;
  stroke-width:0.264583;
}

.common_company_logo__path--white-flame {
  fill: #ffffff;
}

.common_company_logo__path--blue-flame {
  fill: #1f4a95;
  image-rendering: pixelated;
}
.common_company_logo__path--aquamarine-flame {
  fill: #01aaac;
}
.common_company_logo__path--green-flame {
  fill: #7eab44;
}
