.common_company_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eeeeee;
  width: 100%;
  height: 100vh;
}

.common_company_loader__svg {
  width: 15%;
  height: 15%;
}

.common_company_loader__flickering {
  display: inline;
  stroke-width:0.264583;
  opacity: 0;
  animation-duration: 3s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(
    0.68,
    -0.55,
    0.27,
    1.55
  );
}

.common_company_loader__path--white-flame {
  fill: #ffffff;
}

.common_company_loader__path--blue-flame {
  fill: #1f4a95;
  image-rendering: pixelated;
  animation-name: common_company_loader__flicker--3s;
}
.common_company_loader__path--aquamarine-flame {
  fill: #01aaac;
  animation-name: common_company_loader__flicker--2s;
}
.common_company_loader__path--green-flame {
  fill: #7eab44;
  animation-name: common_company_loader__flicker--1s;
}

@keyframes common_company_loader__flicker--3s {
  0% {
    filter: grayscale(0%);
    opacity: 0;
  }
  20%,
  80% {
    filter: grayscale(0.001%);
    opacity: 1;
  }
}

@keyframes common_company_loader__flicker--2s {
  0% {
    filter: grayscale(0%);
    opacity: 0;
  }
  30%,
  70% {
    filter: grayscale(0.001%);
    opacity: 1;
  }
}

@keyframes common_company_loader__flicker--1s {
  0% {
    filter: grayscale(0%);
    opacity: 0;
  }
  40%,
  60% {
    filter: grayscale(0.001%);
    opacity: 1;
  }
}
